import React from "react";

import './header.scss';

function Header() {
  return <header className="header">
    <div className="title">WORDLE COMPANION</div>
  </header>
}

export default Header;
